import { useTranslations } from '@pepita-react/i18n';
import { useSuspenseQuery } from '@tanstack/react-query';
import Link from 'next/link';
import type { SavedSearchResult } from 'src/repository/user/saved-searches/formatted-data-types';

import { getProductConfig } from 'src/config/product';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import {
  PepitaMediaObjectBottom,
  PepitaMediaObjectContent,
  PepitaMediaObjectLink,
  PepitaMediaObjectTitle,
  PepitaMediaObjectTop,
} from 'src/libs/ui/pepita-media-object';
import { PepitaStripe } from 'src/libs/ui/pepita-stripe';

import { LAST_THINGS_TYPES } from 'src/types/last-thing';

import { useGetSavedSearchesQueryOptions } from 'src/views/SavedSearches/hooks/useSavedSearches';

import { LastThingSlider } from '../LastThingSlider';
import { LastThingTitle } from '../LastThingTitle';

import css from './styles.module.scss';

export function SavedSearchesSection({
  titleClass,
  stripeCustomClass,
}: {
  titleClass: string;
  stripeCustomClass: string;
}) {
  const { trans } = useTranslations();
  const queryOptions = useGetSavedSearchesQueryOptions();
  const { data } = useSuspenseQuery(queryOptions);
  const slides = getSlides(data.results.slice(0, 8));

  return (
    <>
      <LastThingTitle
        customClass={titleClass}
        isAuthenticated
        link={getProductConfig('user').savedSearchesUrl}
        linkRef={trans('act_see_all_fe')}
        text={trans('menu_map_lbl_saved_search')}
        icon="bell"
        isVisible={Boolean(data.totalSearches)}
      />
      <LastThingSlider
        type={LAST_THINGS_TYPES.SEARCHES}
        stripeCustomClass={stripeCustomClass}
      >
        {slides}
      </LastThingSlider>
    </>
  );
}

const getSlides = (items: SavedSearchResult[]) =>
  items.map(({ url, locationPreview, title, badges, id }) => {
    const features = badges.moreBadges
      ?.slice(0, 2)
      .map((badge) => <p key={badge}>{badge}</p>);

    return (
      <PepitaStripe.Slide key={id}>
        <Link href={url} passHref legacyBehavior>
          <PepitaMediaObjectLink
            target="_blank"
            customClass={css['hp-savedSearchesSection__card']}
            hoverable
            padded
          >
            <PepitaFigure ratio="square" cover>
              {locationPreview.main ? (
                <PepitaFigure.Image
                  src={locationPreview.main}
                  customClass={css['hp-savedSearchesSection__image']}
                />
              ) : (
                <PepitaFigure.Placeholder
                  customClass={css['hp-savedSearchesSection__placeholder']}
                />
              )}
            </PepitaFigure>
            <PepitaMediaObjectContent
              customClass={css['hp-savedSearchesSection__content']}
            >
              <PepitaMediaObjectTop>
                <PepitaMediaObjectTitle
                  customClass={css['hp-savedSearchesSection__title']}
                >
                  <span
                    className={css['hp-savedSearchesSection__titleElement']}
                  >
                    {title}
                  </span>
                </PepitaMediaObjectTitle>
              </PepitaMediaObjectTop>
              <PepitaMediaObjectBottom
                customClass={css['hp-savedSearchesSection__bottom']}
              >
                {features}
              </PepitaMediaObjectBottom>
            </PepitaMediaObjectContent>
          </PepitaMediaObjectLink>
        </Link>
      </PepitaStripe.Slide>
    );
  });
