import { FILTERS } from 'src/types/saved-searches';
import type { Language } from 'src/types/translations';

export const getQueryKeySavedSearches = (
  pagination: number,
  language: Language,
  isAuthenticated: boolean,
  filter?: FILTERS
) => {
  return ['saved-searches', pagination, language, isAuthenticated, filter];
};

interface SavedSearchesTotal {
  totalSearches: number;
  totalContactsEnabled: number;
  totalRealtimeAlerts: number;
}

export const getTotalSavedSearchesByFilter = (
  {
    totalSearches,
    totalContactsEnabled,
    totalRealtimeAlerts,
  }: SavedSearchesTotal,
  currentFilter: FILTERS
) => {
  if (currentFilter === FILTERS.ACTIVE) {
    return totalContactsEnabled;
  }

  if (currentFilter === FILTERS.REALTIME) {
    return totalRealtimeAlerts;
  }

  return totalSearches;
};
