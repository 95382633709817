import type { ReactElement } from 'react';

import type { StripeSectionId } from 'src/libs/growthbook/client';

import { LAST_THINGS_TYPES } from 'src/types/last-thing';

import { LastRealEstatesSection } from '../LastRealEstatesSection';
import { LastSearchesSection } from '../LastSearchesSection';
import { SavedListingsSection } from '../SavedListingsSection';
import { SavedSearchesSection } from '../SavedSearchesSection';

interface StripeSectionComponentProps {
  titleClass: string;
  stripeCustomClass: string;
}

export const sections: Record<
  StripeSectionId,
  {
    component: (props: StripeSectionComponentProps) => ReactElement;
    fallbackComponent:
      | ((props: StripeSectionComponentProps) => ReactElement)
      | undefined;
    dataCy: string;
  }
> = {
  recent_listings: {
    component: LastRealEstatesSection,
    fallbackComponent: undefined,
    dataCy: `last-things-${LAST_THINGS_TYPES.REALESTATES}`,
  },
  recent_searches: {
    component: LastSearchesSection,
    fallbackComponent: undefined,
    dataCy: `last-things-${LAST_THINGS_TYPES.SEARCHES}`,
  },
  saved_searches: {
    component: SavedSearchesSection,
    fallbackComponent: LastSearchesSection,
    dataCy: `last-things-${LAST_THINGS_TYPES.SEARCHES}`,
  },
  saved_listings: {
    component: SavedListingsSection,
    fallbackComponent: LastRealEstatesSection,
    dataCy: `last-things-${LAST_THINGS_TYPES.REALESTATES}`,
  },
};
