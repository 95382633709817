import { useIsOnClient } from '@indomita-react/custom-hooks';

import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { SuspenseWhen } from 'src/components/SuspenseWhen';

import type { StripeSectionId } from 'src/libs/growthbook/client';

import { LastThingsSkeleton } from '../LastThingSkeleton';

import { sections } from './sections';

interface StripeSectionProps {
  id: StripeSectionId;
  customClass: string;
  titleClass: string;
  stripeCustomClass: string;
}

export function StripeSection({
  id,
  customClass,
  ...props
}: StripeSectionProps) {
  const isOnClient = useIsOnClient();
  const {
    dataCy,
    component: StripeSectionComponent,
    fallbackComponent: FallbackComponent,
  } = sections[id];

  return (
    <section className={customClass} data-cy={dataCy}>
      {id === 'saved_listings' || id === 'saved_searches' ? (
        <ErrorBoundary
          FallbackComponent={
            FallbackComponent && <FallbackComponent {...props} />
          }
        >
          <SuspenseWhen
            when={isOnClient}
            fallback={<LastThingsSkeleton {...props} />}
          >
            <StripeSectionComponent {...props} />
          </SuspenseWhen>
        </ErrorBoundary>
      ) : (
        <StripeSectionComponent {...props} />
      )}
    </section>
  );
}
