import type { SearchMode } from '@indomita-website/search/types';

import { geographySearchToggleMap } from 'src/components/GeographySearch/utils';
import { useComponentControls } from 'src/components/ReactGeographySearch/hooks/useComponentControls';

import { isFeatureEnabled } from 'src/config/features-toggle';

const searchMethodFromMode = {
  0: 'PLACE',
  1: 'DISTANCE',
  2: 'AREA',
  3: 'DISTANCE',
} as const;

export const useOpenGeographySearchModal = () => {
  const { openSearchModal } = useComponentControls();

  const openGeographySearchModal = (mode: SearchMode) => {
    if (isFeatureEnabled('REACT_GEOGRAPHY_SEARCH')) {
      return openSearchModal(searchMethodFromMode[mode], true);
    }

    return geographySearchToggleMap(mode);
  };

  return openGeographySearchModal;
};
