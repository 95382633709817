import { useTranslations } from '@pepita-react/i18n';
import { useSuspenseQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { getProductConfig } from 'src/config/product';

import { ListingFeatures } from 'src/entities/listing/components';
import { formatFeaturesForListingFeatures } from 'src/entities/listing/utils/features';
import { getListingImagePlaceholder } from 'src/entities/listing/utils/images';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import {
  PepitaMediaObjectBottom,
  PepitaMediaObjectContent,
  PepitaMediaObjectLink,
  PepitaMediaObjectTitle,
  PepitaMediaObjectTop,
} from 'src/libs/ui/pepita-media-object';
import { PepitaStripe } from 'src/libs/ui/pepita-stripe';

import { LAST_THINGS_TYPES } from 'src/types/last-thing';
import type { Listing } from 'src/types/listing-search';
import type { Language } from 'src/types/translations';

import { getRealEstateUrl } from 'src/utils/locations';

import { useGetSavedListingsQueryOptions } from 'src/views/SavedListings/api';

import { LastThingSlider } from '../LastThingSlider';
import { LastThingTitle } from '../LastThingTitle';

import css from './styles.module.scss';

export function SavedListingsSection({
  titleClass,
  stripeCustomClass,
}: {
  titleClass: string;
  stripeCustomClass: string;
}) {
  const { trans } = useTranslations();
  const queryOptions = useGetSavedListingsQueryOptions();
  const { data } = useSuspenseQuery(queryOptions);
  const { locale } = useRouter();
  const slides = getSlides(data.items.slice(0, 8), locale as Language);

  return (
    <>
      <LastThingTitle
        customClass={titleClass}
        isAuthenticated
        link={getProductConfig('user').savedAdsUrl}
        linkRef={trans('act_see_all')}
        text={trans('menu_map_lbl_saved_ads')}
        icon="home"
        isVisible={Boolean(data.totalCount)}
      />
      <LastThingSlider
        type={LAST_THINGS_TYPES.REALESTATES}
        stripeCustomClass={stripeCustomClass}
      >
        {slides}
      </LastThingSlider>
    </>
  );
}

const getSlides = (items: Listing[], lang: Language) =>
  items.map(({ id, properties, title, price, disabled }) => {
    const { rooms, surface, multimedia, location } = properties.at(0) || {};
    const { city, microzone } = location || {};
    const features = formatFeaturesForListingFeatures(rooms, surface);
    const url = getRealEstateUrl({ id, lang });

    return (
      <PepitaStripe.Slide key={id}>
        <Link href={url} passHref legacyBehavior>
          <PepitaMediaObjectLink
            target="_blank"
            customClass={clsx(
              css['hp-savedListingsSection__card'],
              disabled && 'is-disabled'
            )}
            hoverable
            padded
          >
            <PepitaFigure ratio="square" cover>
              <PepitaFigure.Image
                src={
                  multimedia?.photos.at(0)?.urls.small ||
                  getListingImagePlaceholder()
                }
                customClass={css['hp-savedListingsSection__image']}
              />
            </PepitaFigure>
            <PepitaMediaObjectContent
              customClass={css['hp-savedListingsSection__content']}
            >
              <PepitaMediaObjectTop>
                <PepitaMediaObjectTitle
                  customClass={css['hp-savedListingsSection__title']}
                >
                  {title}
                </PepitaMediaObjectTitle>
                {city && (
                  <p className={css['hp-savedListingsSection__subtitle']}>
                    {city}
                  </p>
                )}
                {microzone && (
                  <p className={css['hp-savedListingsSection__text']}>
                    {microzone}
                  </p>
                )}
              </PepitaMediaObjectTop>
              <PepitaMediaObjectBottom
                customClass={css['hp-savedListingsSection__bottom']}
              >
                {price.formattedValue && (
                  <p className={css['hp-savedListingsSection__featuresPrice']}>
                    {price.formattedValue}
                  </p>
                )}
                <ListingFeatures features={features} />
              </PepitaMediaObjectBottom>
            </PepitaMediaObjectContent>
          </PepitaMediaObjectLink>
        </Link>
      </PepitaStripe.Slide>
    );
  });
