import { memo, useMemo } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { homepageFormKeyAtom } from '../atoms/homepageFormKeyAtom';
import { homepageFormStateAtom } from '../atoms/homepageFormStateAtom';

import { TypologyMultiselect } from 'src/components/TypologyMultiselect';

import type { CategoryConfig } from '../config/formConfigTypes';
import { FormFields } from '../config/formConfigTypes';

import type { CONTRACT } from 'src/constants/typologies';

import { categoriesConfigAtom } from '../hooks/useCategoriesConfigAtom';

import type { FieldType } from '../types';

import { nonNullable } from 'src/utils/common';
import { getTypologiesFromCategorySelection } from 'src/utils/getTypologies';

const getOptions = (categoriesConfig, contract: CONTRACT) => {
  const configs = categoriesConfig
    .filter((categoryConfig) => categoryConfig.contracts.includes(contract))
    .map((config) => ({
      selected: false,
      label: config.label,
      value: config.value,
      children: config.children.map((child) => ({
        selected: false,
        label: child.label,
        value: `${child.categoryId}_${child.typologyId}`,
        children: [],
      })),
    }));

  configs.forEach((config) => {
    if (config.children.length > 0) {
      config.children.unshift({
        selected: false,
        label: 'lbl_all_typologies', // i18n-extract-keys ["lbl_all_typologies"]
        value: config.value,
        children: [],
      });
    }
  });

  return configs;
};

const getMultiselectData = (category, typology) => {
  if (category === null) {
    return null;
  }

  if (!typology || typology.length === 0) {
    return [`${category}_`];
  }

  return typology.map((typologyValue) => `${category}_${typologyValue}`);
};

export const TypologyField = memo(function TypologyField({
  fieldConfig,
  customClass,
  controlCustomClass,
  selectedClass,
  onChange,
}: FieldType<CategoryConfig>) {
  const { trans } = useTranslations();

  const homepageFormKey = useAtomValue(homepageFormKeyAtom);
  const homepageFormState = useAtomValue(homepageFormStateAtom);

  const typologyData =
    homepageFormState[homepageFormKey]?.[FormFields.TYPOLOGY] ?? null;
  const category =
    homepageFormState[homepageFormKey]?.[FormFields.CATEGORY] ?? null;

  const contractId = homepageFormState[homepageFormKey]?.[FormFields.CONTRACT];

  const categoriesConfig = useAtomicStateAtomValue(categoriesConfigAtom);

  // Compute options (they depend on current contract)
  const options = useMemo(
    () =>
      (contractId &&
        getOptions(categoriesConfig, contractId).map((option) => ({
          ...option,
          // i18n-extract-disable-next-line
          label: trans(option.label),
          children: option.children.map((child) => ({
            ...child,
            // i18n-extract-disable-next-line
            label: trans(child.label),
          })),
        }))) ??
      [],
    [categoriesConfig, contractId, trans]
  );

  // Compute value to give as input to multiselect
  // It depends on category and typology and also options
  // (some old input value could not be listed inside options)
  const typology = useMemo(() => {
    let result = getMultiselectData(category, typologyData);

    const optionExists =
      result &&
      Boolean(
        options.find((option) =>
          option.children.length > 0
            ? option.children.find((child) => result.includes(child.value))
            : result.includes(option.value)
        )
      );

    if (!optionExists) {
      // Reset selection to first option
      // This could happen only on bad initial input
      const newCategory = options[0]?.value.split('_')[0];

      result = getMultiselectData(newCategory, null);
    }

    return result;
  }, [category, typologyData, options]);

  return options ? (
    <TypologyMultiselect
      customClass={clsx(
        customClass,
        (category !== null || typology !== null) && selectedClass
      )}
      customControlClasses={controlCustomClass}
      fieldLabel={fieldConfig.label ?? undefined}
      options={options}
      value={typology?.join(',')}
      onChange={(selection) => {
        if (!selection) {
          return;
        }

        const splittedSelections = selection.map((sel) => sel.split('_'));

        const typologies = splittedSelections
          .map(getTypologiesFromCategorySelection)
          .filter(nonNullable);

        const categoryId = splittedSelections[0][0];

        onChange && onChange(FormFields.CATEGORY, categoryId);
        onChange && onChange(FormFields.TYPOLOGY, typologies);
      }}
      forceOptionClosed
    />
  ) : null;
});
