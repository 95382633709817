import type { GeoJSON } from '@indomita-website/search/types';

import type { BaseEntityWithChildren } from 'src/components/GeographySearch/types';

import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';

export enum FormFields {
  CONTRACT = 'idContratto',
  CATEGORY = 'idCategoria',
  PRICE = 'prezzo',
  MIN_PRICE = 'prezzoMinimo',
  MAX_PRICE = 'prezzoMassimo',
  SURFACE = 'superficie',
  MIN_SURFACE = 'superficieMinima',
  MAX_SURFACE = 'superficieMassima',
  TYPOLOGY = 'idTipologia',
  LANGUAGE = '__lang',
  PLACE = 'place',
  AUTOCOMPLETE = 'autocomplete',
  AUTOCOMPLETE_INTERNATIONAL = 'autocompleteInternational',
  AUTOCOMPLETE_HOLIDAY = 'autocompleteHoliday',
  AGENCY = 'keywords',
  AGENCY_GROUP = 'idFranchising',
  AGENCY_ASSOCIATION = 'fkAssociazione',
  SORT_CRITERION = 'criterio',
  MIN_NUMBER_OF_ROOMS = 'localiMinimo',
  MAX_NUMBER_OF_ROOMS = 'localiMassimo',
  MIN_BEDROOMS = 'camereDaLettoMinimo',
  MAX_BEDROOMS = 'camereDaLettoMassimo',
}

export type AutocompleteEndpoints =
  | 'national'
  | 'international'
  | 'holiday'
  | 'myHome';

type BaseFieldConfig = {
  icon?: PepitaIconProps;
  field: FormFields;
  label: Nullable<string>;
  customClasses?: string;
  controlCustomClasses?: string;
};

export type CategoryConfig = BaseFieldConfig & {
  type: 'category';
};

export type CategoryResidentialConfig = BaseFieldConfig & {
  type: 'categoryResidential';
};

export type CategoryInternationalConfig = BaseFieldConfig & {
  type: 'categoryInternational';
};

export type RangeConfig = BaseFieldConfig & {
  type: 'range';
  fromName: FormFields;
  toName: FormFields;
  translationKeys?: {
    label: string;
    fromValue: {
      label: string;
    };
    toValue: {
      label: string;
    };
    fromToValue: string;
    value: string;
    from: string;
    to: string;
    title: string;
  };
  keepZeros?: boolean;
  shortLabelValues?: boolean;
  conditionType: 'and' | 'or';
  range: {
    condition?: { [field in FormFields]?: (number | string)[] };
    min: number;
    max: number;
    step: {
      [_: number]: number | undefined;
    };
  }[];
};

export type SelectConfig = BaseFieldConfig & {
  type: 'select';
  options: { id: string; value: string }[];
  capitalize?: boolean;
};

export type PlaceConfig = BaseFieldConfig & {
  type: 'place';
  drawEnabled?: boolean;
  distanceSearchEnabled?: boolean;
  metroEnabled?: boolean;
  regionSearchEnabled: boolean;
  countrySearchEnabled: boolean;
  internationalEnabled: boolean;
  autorefill?: boolean;
  onAutoSubmit?: (values: BaseEntityWithChildren[] | GeoJSON) => void;
};

export type InputConfig = BaseFieldConfig & {
  type: 'input';
};

export type AutocompleteConfig = BaseFieldConfig & {
  type: 'autocomplete';
  endpoint: AutocompleteEndpoints;
};

export type FieldConfig =
  | CategoryConfig
  | CategoryResidentialConfig
  | CategoryInternationalConfig
  | RangeConfig
  | SelectConfig
  | PlaceConfig
  | InputConfig
  | AutocompleteConfig;

export type FormConfig = FieldConfig[];
