import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { AgencyCarousel, formatAgencies } from 'src/components/AgencyCarousel';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useAgenciesExpertLastSearch } from '../hooks/useAgenciesExpertLastSearch';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

export function ExpertAgencies({
  customClass,
  titleClass,
  stripeCustomClass,
}: {
  customClass: string;
  titleClass: string;
  stripeCustomClass: string;
}) {
  const { trans } = useTranslations();

  const agenciesExpert = useAgenciesExpertLastSearch();
  const agencyCover = isFeatureEnabled('AGENCIES_EXPERT_COVER');

  return (
    agenciesExpert.agencies && (
      <div className={customClass}>
        <AgencyCarousel
          title={
            <>
              <PepitaIcon name="suitcase" />
              {agenciesExpert.title.label}
              {agenciesExpert.title.url && (
                <PepitaButton
                  variant="ghost"
                  customClass="hp-home__seeAll"
                  href={agenciesExpert.title.url}
                  target="_blank"
                  as={'a'}
                  small
                >
                  {trans('act_see_all_fe')}
                </PepitaButton>
              )}
            </>
          }
          titleClass={titleClass}
          customClass={clsx('nd-grid', stripeCustomClass)}
          items={formatAgencies(agenciesExpert.agencies, 'homepage')}
          hasCover={agencyCover}
          withPromoBox
          from="homepage"
          innerArrow
        />
      </div>
    )
  );
}
