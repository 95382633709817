import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { queryOptions } from '@tanstack/react-query';

import { useQueryParams } from 'src/hooks/useQueryParams';

import type { ListingSearchData } from 'src/types/listing-search';
import type { Language } from 'src/types/translations';

import { http } from 'src/utils/client/http';

export interface GetSavedListingsParams {
  pag?: string | undefined;
  criterio?: string | undefined;
  ordine?: string | undefined;
  filter?: string | undefined;
}

const getSavedListings = (params: GetSavedListingsParams, language: Language) =>
  http
    .get('/api-next/user/saved-listings/', {
      searchParams: { ...params, __lang: language },
    })
    .json<ListingSearchData>();

export const getQueryKeySavedListings = (
  queryParams: GetSavedListingsParams,
  language: Language,
  isAuthenticated: boolean
) => {
  return ['saved-listings', queryParams, language, isAuthenticated];
};

export const getSavedListingsQueryOptions = (
  queryParams: GetSavedListingsParams,
  language: Language,
  isAuthenticated: boolean
) => {
  return queryOptions({
    queryKey: getQueryKeySavedListings(queryParams, language, isAuthenticated),
    queryFn: () => getSavedListings(queryParams, language),
    enabled: isAuthenticated,
  });
};

export const useGetSavedListingsQueryOptions = () => {
  const queryParams = useQueryParams() as GetSavedListingsParams;
  const { lang } = useTranslations() as { lang: Language };
  const { user } = useAuthContext();

  return getSavedListingsQueryOptions(queryParams, lang, Boolean(user));
};
