import type { SuspenseProps } from 'react';
import { Suspense } from 'react';

interface SuspenseWhenProps extends SuspenseProps {
  when: boolean;
}

export function SuspenseWhen({ when, fallback, children }: SuspenseWhenProps) {
  if (!when) return <>{fallback}</>;

  return <Suspense fallback={fallback}>{children}</Suspense>;
}
