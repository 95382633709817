import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';

import {
  useAtomicStateAtomSetState,
  useAtomicStateAtomValue,
} from 'src/atoms/atomic-state';
import { homepageFormKeyAtom } from '../atoms/homepageFormKeyAtom';
import { homepageFormStateAtom } from '../atoms/homepageFormStateAtom';

import { geographySearchParamsAtom } from 'src/components/GeographySearch/atoms';
import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';
import { formStateAtom } from 'src/components/SearchFilters/atoms';
import { FormFields } from 'src/components/SearchFilters/config/formConfigTypes';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { SORTING_CRITERION } from 'src/entities/listing/utils/sorting';

import { useSearchFromHomepageTracking } from './useSearchFromeHomePageTracking';

import type { Language } from 'src/types/translations';
import { HOMEPAGE_FORM_KEYS } from '../types';

import { http } from 'src/utils/client/http';
import { deferCallback } from 'src/utils/performance';
import { serializeIntoUrl } from 'src/utils/querystring';
import { alternateAbsoluteURL } from 'src/utils/url';

const isListingSearchNewSortingEnabled = isFeatureEnabled(
  'LISTING_SEARCH_NEW_SORTING'
);

const formList = [
  HOMEPAGE_FORM_KEYS.SALE,
  HOMEPAGE_FORM_KEYS.RENT,
  HOMEPAGE_FORM_KEYS.AUCTION,
  HOMEPAGE_FORM_KEYS.INTERNATIONAL_SALE,
  HOMEPAGE_FORM_KEYS.INTERNATIONAL_RENT,
  HOMEPAGE_FORM_KEYS.AGENCIES,
];

const getAutocompleteEndPoint = (homepageFormKey: HOMEPAGE_FORM_KEYS) => {
  if (homepageFormKey === HOMEPAGE_FORM_KEYS.APPRAISE) {
    return `${getProductConfig('evaluateBuildingUrl')}valuta-inizio`;
  }

  if (homepageFormKey === HOMEPAGE_FORM_KEYS.SELLER) {
    return getProductConfig('sellerLeadUrl');
  }

  if (homepageFormKey === HOMEPAGE_FORM_KEYS.MYHOME) {
    return getProductConfig('myHomeUrl');
  }

  return;
};

export const useSubmitHandler = () => {
  const router = useRouter();

  const homepageFormKey = useAtomValue(homepageFormKeyAtom);
  const homepageFormState = useAtomValue(homepageFormStateAtom);
  const geographySearch = useAtomicStateAtomValue(geographySearchAtom);
  const geographyParams = useAtomicStateAtomValue(geographySearchParamsAtom);
  const setFormState = useAtomicStateAtomSetState(formStateAtom);
  const [submitState, setSubmitState] = useState<'loading' | 'error' | null>(
    null
  );
  const { trackSearchFromHomepage } = useSearchFromHomepageTracking();

  /*  It's reset to the initial submitState value whenever the user changes the Tab they are interacting with. */
  useEffect(() => {
    setSubmitState(null);
  }, [homepageFormKey]);

  const isValidUrl = (url: string) => {
    return url && url !== window.location.pathname;
  };

  const autocompleteEndPoint = getAutocompleteEndPoint(homepageFormKey);

  return {
    onSubmit: () => {
      setSubmitState('loading');

      // PERF
      deferCallback(() => {
        const homepageCurrentFormState = homepageFormState[homepageFormKey];

        if (homepageCurrentFormState && formList.includes(homepageFormKey)) {
          setFormState(homepageCurrentFormState);
        }

        if (
          [
            HOMEPAGE_FORM_KEYS.APPRAISE,
            HOMEPAGE_FORM_KEYS.SELLER,
            HOMEPAGE_FORM_KEYS.MYHOME,
          ].includes(homepageFormKey) &&
          autocompleteEndPoint !== undefined
        ) {
          if (
            !homepageFormState[homepageFormKey]?.a &&
            !homepageFormState[homepageFormKey]?.q
          ) {
            setSubmitState('error');

            return;
          }

          const endpoint = serializeIntoUrl(autocompleteEndPoint, {
            a: homepageFormState[homepageFormKey]?.a,
            q: homepageFormState[homepageFormKey]?.q,
            from: 'home',
          });

          if (isValidUrl(endpoint)) {
            window.location.href = endpoint;
          }

          setSubmitState(null);
        } else if (
          (!isFeatureEnabled('INTERNATIONAL_SEARCH_ENABLED') &&
            (HOMEPAGE_FORM_KEYS.INTERNATIONAL_SALE === homepageFormKey ||
              HOMEPAGE_FORM_KEYS.INTERNATIONAL_RENT === homepageFormKey)) ||
          HOMEPAGE_FORM_KEYS.HOLIDAY === homepageFormKey
        ) {
          try {
            if (
              (HOMEPAGE_FORM_KEYS.INTERNATIONAL_SALE === homepageFormKey ||
                HOMEPAGE_FORM_KEYS.INTERNATIONAL_RENT === homepageFormKey) &&
              !homepageFormState[homepageFormKey].city
            ) {
              throw new Error('No geography setted');
            }

            if (
              HOMEPAGE_FORM_KEYS.HOLIDAY === homepageFormKey &&
              !homepageFormState[homepageFormKey].area &&
              !homepageFormState[homepageFormKey].city
            ) {
              throw new Error('No geography setted');
            }

            const vertical =
              HOMEPAGE_FORM_KEYS.HOLIDAY === homepageFormKey
                ? 'Seconde case'
                : 'Estero';
            const category = `${
              homepageFormState[homepageFormKey].idCategoria
            }_${homepageFormState[homepageFormKey].idTipologia[0] ?? ''}`;

            http
              .get('/api-next/search-list/external-search-url-resolver/', {
                searchParams: {
                  category,
                  vertical,
                  contract: homepageFormState[homepageFormKey].idContratto,
                  minPrice: homepageFormState[homepageFormKey].prezzoMinimo,
                  maxPrice: homepageFormState[homepageFormKey].prezzoMassimo,
                  minSurface:
                    homepageFormState[homepageFormKey].superficieMinima,
                  maxSurface:
                    homepageFormState[homepageFormKey].superficieMassima,
                  area: homepageFormState[homepageFormKey].area,
                  city: homepageFormState[homepageFormKey].city,
                },
              })
              .json<{ url: string }>()
              .then((res) => res?.url)
              .then((url) => {
                if (isValidUrl(url)) {
                  window.location.href = url;
                } else {
                  setSubmitState(null);
                }
              });
          } catch {
            setSubmitState('error');
          }
        } else {
          try {
            const isEmptyGeo = !Object.keys(geographySearch || {}).length;
            const isAgenciesTabActive =
              homepageFormKey === HOMEPAGE_FORM_KEYS.AGENCIES;

            const isDisabledGeographyForTabAgency =
              isAgenciesTabActive &&
              isFeatureEnabled('AGENCIES_HOMEPAGE_SEARCH_LOCALITY_DISABLED');

            const isGroupAgencySelected =
              homepageFormState[homepageFormKey]?.['idFranchising'];

            if (
              isEmptyGeo &&
              !isDisabledGeographyForTabAgency &&
              !isGroupAgencySelected
            ) {
              throw new Error('No geography setted');
            }

            const endpoint = isAgenciesTabActive
              ? '/api-next/agencies/url-resolver/'
              : '/api-next/search-list/url-resolver/';

            return http
              .get(endpoint, {
                searchParams: {
                  ...homepageFormState[homepageFormKey],
                  ...geographyParams,
                  ...(!isListingSearchNewSortingEnabled && {
                    [FormFields.SORT_CRITERION]: SORTING_CRITERION.relevance,
                  }),
                  __lang: router.locale,
                },
              })
              .json<string | { url: string }>()
              .then((data) => {
                const url = typeof data === 'string' ? data : data.url;

                if (isValidUrl(url)) {
                  /**
                   * When we push an url we don't need to revalidate the page state from the server
                   */

                  trackSearchFromHomepage({
                    geographyData: geographySearch,
                    geographyParams,
                    homepageFormState: homepageFormState[homepageFormKey],
                    homepageFormKey,
                  });

                  // Currently the agencies pages are on another microfrontend
                  if (homepageFormKey === HOMEPAGE_FORM_KEYS.AGENCIES) {
                    // if url is a rewrite, we redirect to exact ws url
                    window.location.href = alternateAbsoluteURL(
                      url,
                      router.defaultLocale as Language,
                      router.locale as Language
                    );
                  } else {
                    router.push(url, undefined, {
                      shallow: true,
                    });
                  }
                } else {
                  setSubmitState(null);
                }
              });
          } catch {
            setSubmitState('error');
          }
        }
      });
    },
    submitState,
  };
};
