import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type { SavedSearchesData } from 'src/repository/user/saved-searches/formatted-data-types';

import { useQueryParams } from 'src/hooks/useQueryParams';

import { FILTERS } from 'src/types/saved-searches';
import type { Language } from 'src/types/translations';

import { http } from 'src/utils/client/http';
import { getQueryKeySavedSearches } from '../utils';

const useGetSavedSearchesParams = () => {
  const { user } = useAuthContext();
  const { lang } = useTranslations() as { lang: Language };
  const { filter = FILTERS.ALL, pag = '1' } = useQueryParams() as {
    filter?: FILTERS;
    pag?: string;
  };
  const currentPage = Number(pag);

  return { user, lang, filter, currentPage };
};

export const useSavedSearchesQueryKey = () => {
  const { user, lang, filter, currentPage } = useGetSavedSearchesParams();

  return getQueryKeySavedSearches(currentPage, lang, Boolean(user), filter);
};

export const useGetSavedSearchesQueryOptions = () => {
  const { user, lang, filter, currentPage } = useGetSavedSearchesParams();

  return {
    queryKey: getQueryKeySavedSearches(
      currentPage,
      lang,
      Boolean(user),
      filter
    ),
    queryFn: () => {
      if (!user) return Promise.resolve({} as SavedSearchesData);

      return http
        .get('/api-next/user/saved-searches/', {
          searchParams: {
            pag: currentPage,
            filter,
          },
        })
        .json<SavedSearchesData>();
    },
  };
};

export const useSavedSearches = () => {
  const { user, filter, currentPage } = useGetSavedSearchesParams();
  const { data, isFetching } = useQuery({
    ...useGetSavedSearchesQueryOptions(),
    enabled: Boolean(user),
    placeholderData: keepPreviousData,
  });

  const queryClient = useQueryClient();
  const refetchQuery = () =>
    queryClient.removeQueries({
      queryKey: ['saved-searches'],
      exact: false,
    });

  return {
    savedSearchedData: data,
    currentPage,
    refetchQuery,
    isFetching,
    currentFilter: filter,
  };
};
