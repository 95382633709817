import { type Feature, FEATURE_LIST_TYPE } from 'src/types/real-estate';

export const formatFeaturesForListingFeatures = (
  rooms?: string,
  surface?: string,
  bathrooms?: string,
  floor?: string
): Feature[] => {
  const features: Feature[] = [];

  if (rooms) {
    features.push({
      type: FEATURE_LIST_TYPE.ROOMS,
      label: rooms,
    });
  }

  if (surface) {
    features.push({
      type: FEATURE_LIST_TYPE.SURFACE,
      label: surface,
    });
  }

  if (bathrooms) {
    features.push({
      type: FEATURE_LIST_TYPE.BATHROOMS,
      label: bathrooms,
    });
  }

  if (floor) {
    features.push({
      type: FEATURE_LIST_TYPE.FLOOR,
      label: floor,
    });
  }

  return features;
};
