import { useEffect } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { clsx } from 'clsx';
import { useSetAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { AppBanner } from 'src/components/AppBanner';
import { AssociatedCards } from 'src/components/AssociatedServices';
import { Footer } from 'src/components/Footer';
import { Header } from 'src/components/Header';
import { Hero } from 'src/components/Hero';
import { ListOfDossier } from 'src/components/ListOfDossier';
import type { FormFields } from 'src/components/SearchFilters/config/formConfigTypes';
import { UserTabWrapper } from 'src/components/UserTabWrapper';
import { ConditionalWrapper } from './components/ConditionalWrapper';
import { CountrySearch } from './components/CountrySearch';
import { getUrlPrefix } from './components/CountrySearch/getUrlPrefix';
import { Discover } from './components/Discover';
import { DownloadApp } from './components/DownloadApp';
import { ExpertAgencies } from './components/ExpertAgencies';
import { Franchising } from './components/Franchising';
import { MainPartner } from './components/MainPartner';
import { MobileButtons } from './components/MobileButtons';
import { NumberOneLink } from './components/NumberOneLink';
import { homepageFormKeyAtom } from './components/SearchBox/atoms/homepageFormKeyAtom';
import { homepageFormStateAtom } from './components/SearchBox/atoms/homepageFormStateAtom';
import type { HOMEPAGE_FORM_KEYS } from './components/SearchBox/types';
import { SeoMap } from './components/SeoMap';
import { SeoSiteMap } from './components/SeoSiteMap';
import { Snowflakes } from './components/Snowflakes';
import { StripeSection } from './components/StripeSection';

import { isFeatureEnabled } from 'src/config/features-toggle';
import type { ProductConfig } from 'src/config/product';
import { getProductConfig } from 'src/config/product';

import { VERTICALS } from 'src/constants/typologies';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';

import { useFooterTopConfig } from 'src/hooks/useFooterTopConfig';
import { useDiscoverConfig } from './hooks/useDiscover';
import { useHomeHeaderLinkConfig } from './hooks/useHomeHeaderLink';
import { useHomepageTracking } from './hooks/useHomepageTracking';

import { useGrowthBookFeature } from 'src/libs/growthbook/client';

import { setGA4SectionName } from 'src/tracking/ga/utils/store';
import { setEntryPoint } from 'src/tracking/utils';
import { EntryPoint, SectionName } from 'src/tracking/utils/enums';

import type { HomepageData } from 'src/types/homepage';
import type { Language } from 'src/types/translations';

import { isChristmasTime } from 'src/utils/client/christmas';
import { alternateAbsoluteURL } from 'src/utils/url';

import { SearchBox } from 'src/views/Homepage/components/SearchBox';

import css from './styles.module.scss';

interface HomepageProps {
  homepageData: HomepageData;
  jotaiAtoms: {
    homePageFormKey: HOMEPAGE_FORM_KEYS;
    homepageFormState: {
      [key in HOMEPAGE_FORM_KEYS]: {
        [FormFields.CATEGORY]: Nullable<string>;
        [FormFields.CONTRACT]: Nullable<string>;
        [FormFields.LANGUAGE]: Language;
        [FormFields.SORT_CRITERION]: string;
        [FormFields.TYPOLOGY]: unknown[];
      };
    };
  };
}

const hasTallLogo =
  isFeatureEnabled('TALL_LOGO') && isFeatureEnabled('NEW_LOGO');

export function Homepage({ homepageData, jotaiAtoms }: HomepageProps) {
  const { trans } = useTranslations();
  const setSearchInfo = useSetAtom(searchInfoAtom);
  const { trackBannerClick, trackHomepageViewed } = useHomepageTracking();

  useEffect(() => {
    setEntryPoint(EntryPoint.HOME);
    setGA4SectionName(SectionName.HOMEPAGE);
  }, []);

  useEffect(() => {
    trackHomepageViewed();
  }, [trackHomepageViewed]);

  useHydrateAtoms([
    [homepageFormKeyAtom, jotaiAtoms.homePageFormKey],
    [homepageFormStateAtom, jotaiAtoms.homepageFormState],
  ]);
  const headerMenuConfig = useHomeHeaderLinkConfig();
  const footerTopConfig = useFooterTopConfig();
  const discoverConfig = useDiscoverConfig();
  const stripeSections = useGrowthBookFeature('order_home_sections_web', [
    'recent_searches',
    'recent_listings',
  ]);
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const logoConfig: ProductConfig['logoConfig'] =
    getProductConfig('logoConfig');
  const headlineSvg: ProductConfig['headlineSvg'] =
    getProductConfig('headlineSvg');

  const { defaultLocale, locale, asPath } = useRouter();
  const {
    pageContent,
    pageLookup,
    seoSiteMap,
    seoMap,
    pageContent: { headline },
  } = homepageData;

  const handleOnClickText = (url: Nullable<string>) =>
    url
      ? () => {
          // setTimeout was added to prevent window.open from causing a long task
          setTimeout(() => {
            window.open(
              alternateAbsoluteURL(
                url,
                defaultLocale as Language,
                locale as Language
              ),
              '_blank'
            );
          }, 0);

          trackBannerClick('Logo');
        }
      : undefined;

  const isAgenciesHome: boolean =
    `${pageContent.categoryId}` === VERTICALS.AGENZIA;

  const enabledCountrySearch =
    isFeatureEnabled('COUNTRY_SEARCH_SHORTCUT') && !isAgenciesHome;

  useEffect(() => {
    setSearchInfo(undefined);
  }, [setSearchInfo]);

  return (
    <>
      <Head>
        <link
          rel="preload"
          href={logoConfig.url}
          as="image"
          type="image/svg+xml"
          fetchpriority="high"
        />
      </Head>
      <div className={css['hp-home__headerAndHero']}>
        <ConditionalWrapper
          condition={isFeatureEnabled('SNOWFLAKES') && isChristmasTime()}
          wrapper={(children) => <Snowflakes>{children}</Snowflakes>}
        >
          <Header
            {...headerMenuConfig}
            boxed
            showUser
            customClass={css['hp-home__head']}
            isBranded
          />
          <Hero
            customClass={css['hp-hero']}
            headline={headline.text}
            showSvgHeadline={headlineSvg}
            brand={{
              url: logoConfig.url,
              width: logoConfig.width,
              height: logoConfig.height,
              svgCustomClass: hasTallLogo
                ? css['hp-hero__tallLogo']
                : undefined,
            }}
            brandLink={alternateAbsoluteURL(
              '/',
              defaultLocale as Language,
              locale as Language
            )}
            onClickText={handleOnClickText(headline.url)}
          >
            <SearchBox pageContent={pageContent} pageLookup={pageLookup} />

            {isFeatureEnabled('NUMBER_ONE_LINK') &&
              !homepageData.seoSiteMap &&
              !maxScreenWidthSm && (
                <NumberOneLink customClass={css['hp-home--mobileHidden']} />
              )}
          </Hero>
        </ConditionalWrapper>
      </div>
      {!seoSiteMap && (
        <>
          <MobileButtons />
          {enabledCountrySearch && (
            <CountrySearch
              customClass={clsx(
                css['hp-home--vspacesTop'],
                css['hp-home--vspacesSmallBottom']
              )}
              titleClass={css['hp-home__titleSection']}
              stripeCustomClass={css['hp-home__stripe']}
              urlPrefix={getUrlPrefix(
                asPath,
                defaultLocale as Language,
                locale as Language
              )}
            />
          )}
          {stripeSections.map((id) => (
            <StripeSection
              key={id}
              id={id}
              customClass={clsx(
                css['hp-home--vspacesTop'],
                css['hp-home--vspacesSmallBottom']
              )}
              titleClass={css['hp-home__titleSection']}
              stripeCustomClass={css['hp-home__stripe']}
            />
          ))}
          <DownloadApp customClass={css['hp-home--vspaces']} />
          {isFeatureEnabled('AGENCIES_EXPERT_HOME') && (
            <ExpertAgencies
              customClass={clsx(
                css['hp-home--vspaces'],
                css['hp-home__carouselAgency']
              )}
              titleClass={clsx('nd-grid', css['hp-home__titleSection'])}
              stripeCustomClass={css['hp-home__carouselAgencyStripe']}
            />
          )}
          {discoverConfig.length > 0 && (
            <Discover
              discover={{ items: discoverConfig }}
              customClass={clsx(
                'nd-grid',
                !isFeatureEnabled('DISCOVER_TITLE') && css['hp-home--vspaces']
              )}
              title={
                isFeatureEnabled('DISCOVER_TITLE')
                  ? trans('featured_services', {
                      capitalize: true,
                    })
                  : undefined
              }
              titleClass={clsx(
                'nd-grid',
                css['hp-home--vspaces'],
                css['hp-home__titleSection']
              )}
            />
          )}
          {isFeatureEnabled('ASSOCIATED_SERVICES') && (
            <AssociatedCards
              customClass={clsx('nd-grid', css['hp-home--vspaces'])}
              titleClass={css['hp-home__titleSection']}
            />
          )}
          {isFeatureEnabled('DOSSIERS_ENABLED') && (
            <ListOfDossier
              title={trans('lu_dossiers_real_estate', {
                capitalize: true,
              })}
              titleClass={clsx(
                css['hp-home__titleSection'],
                css['hp-home--vspacesBottom']
              )}
              customClass={clsx('nd-grid', css['hp-home--vspaces'])}
            />
          )}
          {seoMap?.franchising && (
            <Franchising
              customClass={clsx('nd-grid', css['hp-home--vspaces'])}
              franchising={seoMap.franchising}
            />
          )}
        </>
      )}
      {/* home seomap */}
      {seoMap && <SeoMap pageContent={pageContent} seoMap={seoMap} />}
      {/* page seomap */}
      {seoSiteMap && <SeoSiteMap seoSiteMap={seoSiteMap} />}
      {isFeatureEnabled('MAIN_PARTNER_HOME') && (
        <MainPartner customClass={css['hp-home--vspaces']} />
      )}
      <Footer
        footerTop={{
          direction: 'column',
          sections: footerTopConfig,
          customClass: css['hp-home__footerTop'],
        }}
      />
      <AppBanner withUserTab />
      <UserTabWrapper tabSectionActive="search" />
    </>
  );
}
